//checkbox
var checkbox = document.querySelectorAll('[name]:not(a)');

if (checkbox) {
    checkbox.forEach(function (div) {
        div.addEventListener('click', function () {
            var targetName = div.getAttribute('name');
            var checkbox = document.getElementById(targetName);
            if (checkbox && checkbox.type === 'checkbox') {
                checkbox.checked = !checkbox.checked;
            }
        });
    });
}


//tooltip

var data_tooltip = document.querySelectorAll('[data-tooltip]');

if (data_tooltip) {
    data_tooltip.forEach(function (element) {
        element.addEventListener('mousemove', function (event) {
            const tooltipText = this.getAttribute('data-tooltip');
            const tooltip = document.getElementById('tooltip');

            tooltip.textContent = tooltipText;
            tooltip.style.top = (event.pageY + 5) + 'px';
            tooltip.style.left = (event.pageX + 5) + 'px';
            tooltip.style.display = 'block';
        });

        element.addEventListener('mouseout', function () {
            const tooltip = document.getElementById('tooltip');

            tooltip.style.display = 'none';
            tooltip.textContent = '';
            tooltip.style.top = '0';
            tooltip.style.left = '0';
        });
    });
}

//copytext
var copy_icon = document.querySelectorAll(".copy-icon");

if (copy_icon) {

    copy_icon.forEach(function (element) {
        element.addEventListener("click", function () {
            const textToCopy = document.getElementById("text-to-copy").innerText;

            const tempInput = document.createElement("textarea");
            tempInput.value = textToCopy;
            document.body.appendChild(tempInput);

            tempInput.select();
            document.execCommand("copy");

            document.body.removeChild(tempInput);

            const icon = document.getElementById("copy-icon");
            const tooltipBlock = document.getElementById("tooltip");
            const originalTooltip = icon.getAttribute("data-tooltip");
            const originalTooltipBlockText = tooltipBlock.innerText;

            icon.setAttribute("data-tooltip", "Скопировано!");
            tooltipBlock.innerText = "Скопировано!";

            setTimeout(function () {
                icon.setAttribute("data-tooltip", originalTooltip);
                tooltipBlock.innerText = originalTooltipBlockText;
            }, 5000);
        });
    });
}


//animation-on-scroll
const reveals = document.querySelectorAll('.reveal');

if (reveals) {

    const revealsOptions = {
        root: null,
        threshold: 0
    };


    function revealsHandleIntersect(entries, revealsObserver) {
        entries.forEach(entry => {
            const width = window.innerWidth;
            const height = window.innerHeight;
            //if (width < 1028) {
            if (entry.isIntersecting) {
                entry.target.classList.add('active-reveal');
            } else {
                //entry.target.classList.remove('active-reveal');  
            }
            //}
        });
    }


    const revealsObserver = new IntersectionObserver(revealsHandleIntersect, revealsOptions);


    reveals.forEach(reveal => {
        revealsObserver.observe(reveal);
    });
}

//accordions-animate
class Accordion {
    constructor(el) {

        this.el = el;

        this.summary = el.querySelector('summary');

        this.content = el.querySelector('.accordion-card__subtitle-wrapper');


        this.animation = null;

        this.isClosing = false;

        this.isExpanding = false;

        this.summary.addEventListener('click', (e) => this.onClick(e));

        this.k = 20;
    }

    onClick(e) {

        e.preventDefault();

        this.el.style.overflow = 'hidden';

        if (this.isClosing || !this.el.open) {
            this.open();

        } else if (this.isExpanding || this.el.open) {
            this.shrink();
        }
    }

    shrink() {

        this.isClosing = true;


        const startHeight = `${this.el.offsetHeight + this.k}px`;

        const endHeight = `${this.summary.offsetHeight + this.k}px`;


        if (this.animation) {

            this.animation.cancel();
        }


        this.animation = this.el.animate({

            height: [startHeight, endHeight]
        }, {
            duration: 400,
            easing: 'ease-out'
        });


        this.animation.onfinish = () => this.onAnimationFinish(false);

        this.animation.oncancel = () => this.isClosing = false;
    }

    open() {

        this.el.style.height = `${this.el.offsetHeight + this.k}px`;

        this.el.open = true;

        window.requestAnimationFrame(() => this.expand());
    }

    expand() {

        this.isExpanding = true;

        const startHeight = `${this.el.offsetHeight}px`;

        const endHeight = `${this.summary.offsetHeight + this.content.offsetHeight + 20}px`;


        if (this.animation) {

            this.animation.cancel();
        }


        this.animation = this.el.animate({

            height: [startHeight, endHeight]
        }, {
            duration: 400,
            easing: 'ease-out'
        });

        this.animation.onfinish = () => this.onAnimationFinish(true);

        this.animation.oncancel = () => this.isExpanding = false;
    }

    onAnimationFinish(open) {

        this.el.open = open;

        this.animation = null;

        this.isClosing = false;
        this.isExpanding = false;

        this.el.style.height = this.el.style.overflow = '';
    }
}

var accordions = document.querySelectorAll('details');

if (accordions) {
    accordions.forEach((el) => {
        new Accordion(el);
    });
}





//scrollable
const scrollableElements = document.querySelectorAll('.scrollable');

if (scrollableElements) {

    scrollableElements.forEach(element => {
        let isMouseDown = false;
        let startY, startX;
        let scrollTop, scrollLeft;

        element.addEventListener('mousedown', function (e) {
            isMouseDown = true;
            startY = e.clientY;
            startX = e.clientX;
            scrollTop = element.scrollTop;
            scrollLeft = element.scrollLeft;
            element.style.cursor = 'grabbing';
        });

        element.addEventListener('mousemove', function (e) {
            if (isMouseDown) {
                const deltaY = e.clientY - startY;
                const deltaX = e.clientX - startX;
                element.scrollTop = scrollTop - deltaY;
                element.scrollLeft = scrollLeft - deltaX;
            }
        });

        element.addEventListener('mouseup', function () {
            isMouseDown = false;
            element.style.cursor = 'grab';
        });

        element.addEventListener('mouseleave', function () {
            isMouseDown = false;
            element.style.cursor = 'grab';
        });
    });

}

//popup

const orderButtons = document.querySelectorAll('.order');
const closeButton = document.querySelectorAll('.form-block__close');
const popUp = document.querySelector('.popup-order-wrapper');
const mainContent = document.querySelector('.content-wrapper');

if (orderButtons.length && closeButton && popUp && mainContent) {

    orderButtons.forEach(button => {
        button.addEventListener('click', function () {
            popUp.classList.remove('closing');
            popUp.classList.add('active');
            mainContent.style.filter = 'blur(5px)';
        });
    });

    closeButton.forEach(button => {
        button.addEventListener('click', function () {
            popUp.classList.add('closing');
            mainContent.style.filter = 'blur(0)';

            setTimeout(function () {
                popUp.classList.remove('active');
            }, 300);
        });
    });
}


//crm

var crm_form = document.querySelectorAll('.crm-form');

if (crm_form) {
    crm_form.forEach(form => {
        form.addEventListener('submit', function (e) {

            const checkbox = form.querySelector('.form__checkbox');
            const messageOk = document.querySelector('.message-ok');
            const messageError = document.querySelector('.message-error');


            const name = form.querySelector('.form__name-field');
            const phone = form.querySelector('.form__phone-field');
            const phoneRegex = /^(\+?\d{1,3})?[-.\s]?(\(?\d{3}\)?)[-.\s]?\d{3}[-.\s]?\d{4}$/;

            const submitButton = form.querySelector('.form__submit');

            const captcha = form.querySelector('.captcha');

            e.preventDefault();

            if (!checkbox.checked) {
                submitButton.disabled = true;
                messageError.innerHTML = 'Пожалуйста, подтвердите согласие с пользовательским соглашением для отправки заявки.';
                messageError.classList.add('active');
                setTimeout(() => {
                    messageError.classList.remove('active');
                    messageError.innerHTML = '';
                    submitButton.disabled = false;
                }, 5000);
            }

            if (!phoneRegex.test(phone.value)) {
                submitButton.disabled = true;
                messageError.innerHTML = 'Пожалуйста, заполните номер телефона.';
                messageError.classList.add('active');
                setTimeout(() => {
                    messageError.classList.remove('active');
                    messageError.innerHTML = '';
                    submitButton.disabled = false;
                }, 5000);
            }

            if (name.value.length < 3) {
                submitButton.disabled = true;
                messageError.innerHTML = 'Пожалуйста, введите свое имя.';
                messageError.classList.add('active');
                setTimeout(() => {
                    messageError.classList.remove('active');
                    messageError.innerHTML = '';
                    submitButton.disabled = false;
                }, 5000);
            }

            if (checkbox.checked && name.value.length >= 3 && name.value.length < 30 && phoneRegex.test(phone.value) && !captcha.checked) {

                const formData = new FormData(e.target);
                const data = {
                    message: `Новая заявка с сайта:\nИмя: ${formData.get('name')}\nТелефон: ${formData.get('phone')}`};

                fetch('/wp-admin/admin-ajax.php?action=send_message', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: new URLSearchParams(data) // Форматируем данные
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.success) {
                            //alert('Ваш заказ отправлен!');
                            submitButton.disabled = true;

                            name.value = '';
                            phone.value = '';
                            checkbox.checked = false;

                            messageOk.innerHTML = 'Ваша заявка отправлена!';
                            messageOk.classList.add('active');
                            setTimeout(() => {
                                messageOk.classList.remove('active');
                                messageOk.innerHTML = '';
                                submitButton.disabled = false;
                            }, 5000);
                        } else {
                            //alert('Ошибка: ' + data.data); // Выводим ошибку, если есть
                            messageError.innerHTML = 'Ошибка отправки, перезагрузите страницу и попробуйте еще раз.';
                            messageError.classList.add('active');
                            setTimeout(() => {
                                messageError.classList.remove('active');
                                messageError.innerHTML = '';
                            }, 5000);
                        }
                    })
                    .catch(error => {
                        console.error('Ошибка:', error);
                        messageError.innerHTML = 'Ошибка отправки, перезагрузите страницу и попробуйте еще раз';
                        messageError.classList.add('active');
                        setTimeout(() => {
                            messageError.classList.remove('active');
                            messageError.innerHTML = '';
                        }, 5000);
                        //alert('Произошла ошибка при отправке заказа.');
                    });
            }
        });
    });
}